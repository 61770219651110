@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

:root {
  --main-bg-color: #fdfdfd;
  --bg-white: #ffffff;
  --bg-purple: #7367f0;
  --bg-tomato: #ea5455;
  --bg-blue: #0396ff;
  --bg-green: #28c76f;
  --bg-cyand: #32ccbc;
  --bg-yellow: #f8d800;
  --cyan-gradient: linear-gradient(135deg, #90f7ec 10%, #32ccbc 100%);
  --green-gradient: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  --blue-gradient: linear-gradient(135deg, #6b73ff 10%, #000dff 100%);
  --pruple-gradient: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
  --shadow-color: rgba(236, 50, 50, 0.08);
  --hover-shadow-color: rgba(0, 0, 0, 0.05);
}

main > .container {
  padding: 60px 15px 0;
}

input,
select,
textarea,
.search-label {
  width: 100%;
}

input[type='radio'] {
  width: auto;
}

.react-bs-table-no-data {
  text-align: center;
}

.sidebar{
  position: fixed; /* Fija el sidebar en la ventana */
  top: 0;
  left: 0;
  z-index: 1000; /* Asegura que esté sobre otros elementos si es necesario */
}
.container-fluid{
  margin-left: 250px;
  width: auto;
}

footer, .sticky-footer{
  margin-left: 250px;
  width: auto;
}
.sidebar-logo {
  padding: 20px;
  text-align: center;
  margin-top: 14rem;
}

.sidebar-logo img {
  width: 120px;
  height: auto;
}
